import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import ScheduleType from "components/Common/ScheduleType";
import SearchFilterDomain from "components/Common/SearchFilterDomain";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import EmojiPicker from "emoji-picker-react";
import { useFormik } from "formik";
import {
    SCHEDULE_MONTHLY_OPTIONS,
    SCHEDULE_OPTIONS,
    SCHEDULE_WEEKLY_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";
import { createCampaign, putCampaign } from "store/thunks";
import * as Yup from "yup";

import Hashtag from "components/Common/Hashtag";
import ModalConfirm from "components/Common/ModalConfirm";
import SearchFilterTag from "components/Common/SearchFilterTag";
import TooltipCustom from "components/Common/TooltipCustom";
import "./CampaignFormStyled.scss";

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean;
    listDomain: any[];
    triggerRefresh?: () => void;
    data?: any | null;
    isUpdate?: boolean;
}

registerLocale("en", en);
registerLocale("ko", ko);

const CampaignForm = ({
    isModal = false,
    id = "",
    listDomain = [],
    triggerRefresh,
    data = null,
    isCopy = false,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // const { id = "" } = useParams();

    const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

    const MONTHLY_OPTIONS =
        SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "monthly",
        })) || [];
    const WEEKLY_OPTIONS =
        SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "weekly",
        })) || [];
    const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map(
        (item: any) => ({ ...item, label: t(item?.label) })
    );

    const OPTIONS_MEDIA =
        listDomain?.map((item) => ({
            label: item?.name,
            value: String(item?.id),
            website: item?.website,
        })) || [];

    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
    const [emojiPickerTitle, setEmojiPickerTitle] = useState<boolean>(false);
    const [emojiPickerContent, setEmojiPickerContent] = useState<boolean>(false);
    const [emojiTitle, setEmojiTitle] = useState<string>("");
    const [emojiContent, setEmojiContent] = useState<string>("");
    const emojiPickerContainerTitleRef = useRef<HTMLDivElement>(null);
    const emojiPickerContainerContentRef = useRef<HTMLDivElement>(null);

    const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);

    const handleShowConfirmCreateCampaign = () => {
        setIsConfirmCreate(true);
    };

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data: any = {
                name: values?.campaignName,
                push_title: values?.title,
                push_content: values?.content,
                push_url: values?.url,
                image_url: values?.imageURL,
                schedule: values?.schedule?.value,
            };
            if (values?.tags) {
                data.hashtag = values?.tags?.map((item: any) => item.text).join(",");
            }
            if (values?.target_tag) {
                data.targettag = values?.target_tag?.map((item: any) => item.label).join(",");
            }

            if (values?.schedule?.value === "weekly") {
                data.date = values?.date?.value;
                data.time = values?.time;
            }

            if (values?.schedule?.value === "daily") {
                data.time = values?.time;
            }

            if (values?.schedule?.value === "monthly") {
                data.date = values?.date?.value;
                data.time = values?.time;
            }

            if (values?.schedule?.value === "custom") {
                data.date = values?.custom_date;
                data.time = values?.time;
            }

            if (id && isCopy === false) {
            } else {
                data.media_id =
                    values?.domain?.map((item: any) => item.value).join(",") ||
                    OPTIONS_MEDIA[0]?.value;
            }

            const response: any =
                id && isCopy === false
                    ? await putCampaign(id, data)
                    : await createCampaign(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                setIsConfirmCreate((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                if (id) {
                    triggerRefresh && triggerRefresh();
                } else {
                    navigate("/campaign/list");
                }
            } else {
                setIsLoading((_prev) => false);
                toast(`${t(response)}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            setIsConfirmCreate((_prev) => false);
            toast(`${t(error?.message) || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        campaignName: Yup.string().required(`${t("Campaign Name is required")}`),
        title: Yup.string().required(`${t("Title is required")}`),
        content: Yup.string().required(`${t("Content is required")}`),
        date: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("schedule", {
                is: (schedule: any) =>
                    OPTIONS_MUST_HAVE_DATE?.includes(
                        (formik.values?.schedule as any)?.value
                    ),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Day is required")}`),
            })
            .nullable(),

        time: Yup.string()
            .when("schedule", {
                is: (schedule: any) =>
                    ["daily", "weekly", "monthly", "custom"].includes(
                        (formik.values?.schedule as any)?.value
                    ),
                then: (schema) => Yup.string().required(`${t("Time is required")}`),
            })
            .nullable(),
        custom_date: Yup.string()
            .when("schedule", {
                is: (schedule: any) => {
                    return (formik.values?.schedule as any)?.value === "custom";
                },
                then: (schema) => Yup.string().required(`${t("Date is required")}`),
            })
            .nullable(),
        domain: Yup.array()
            .required(`${t("Media is required")}`)
            .min(1, t("Media is required")),

        // domain: Yup.lazy(value => {
        //     if (Array.isArray(value)) {
        //         return Yup.array().min(1, `Domain is required`).required();
        //     } else {
        //         return Yup.object().shape({
        //             label: Yup.string().required(),
        //             value: Yup.string().required(),
        //         }).required(`${t("Domain is required")}`);
        //     }
        // })
    });

    const formik = useFormik({
        initialValues: {
            campaignName: "",
            title: "",
            content: "",
            url: "",
            imageURL: "",
            schedule: null,
            date: null,
            time: null,
            domain: [],
            iconURL: "",
            custom_date: "",
            tags: [],
            target_tag: [],
        },
        validationSchema,
        onSubmit: handleShowConfirmCreateCampaign,
    });
    //@ts-ignore
    const initialValueTags = (formik?.values?.tags as Tag[]) || [];

    const initialValueTargetTags = (formik?.values?.target_tag as Option[]) || [];

    const setDataFromList = () => {
        if (data?.id) {
            const {
                media,
                name,
                time,
                push_title,
                push_content,
                push_url,
                image_url,
                schedule,
                date,
                hashtags,
                targettags
            } = data;

            const vDomain = OPTIONS_MEDIA.filter((option) => media.some((m: any) => m.id === option.value)) || [];
            const valueUpdate: any = {
                domain: vDomain,
                schedule: SCHEDULE_OPTIONS_LANG.find((item) => item.value === schedule),
                campaignName: name,
                title: push_title,
                content: push_content,
                url: push_url,
                imageURL: image_url,
                time: time ?? null,
                tags: hashtags?.map((item: any) => ({
                    id: item,
                    text: item,
                })) || [],
                target_tag: targettags?.map((item: any) => ({
                    label: item,
                    value: item,
                })) || [],
            };

            if (schedule !== "custom") {
                valueUpdate.date = DATE_OPTIONS_LANG.find((item) => item.value === date);
            } else {
                valueUpdate.custom_date = moment(date).format("yyyy-MM-DD");
            }

            formik.setValues({ ...formik.values, ...valueUpdate });
        }
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                emojiPickerContainerTitleRef.current &&
                !emojiPickerContainerTitleRef.current.contains(event.target)
            ) {
                setEmojiPickerTitle(false);
            }

            if (
                emojiPickerContainerContentRef.current &&
                !emojiPickerContainerContentRef.current.contains(event.target)
            ) {
                setEmojiPickerContent(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [emojiPickerContainerTitleRef, emojiPickerContainerContentRef]);

    useEffect(() => {
        setDataFromList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (emojiTitle) {
            formik.setFieldValue("title", formik.values?.title + emojiTitle);
            setEmojiTitle("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emojiTitle]);

    useEffect(() => {
        if (emojiContent) {
            formik.setFieldValue("content", formik.values?.content + emojiContent);
            setEmojiContent("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emojiContent]);

    const onSaveClick = () => {
        formik.submitForm();
    };

    useEffect(() => {
        document.title = `${t("Campaign Create")} - ${t("Campaign")} | MessageHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const handleReset = () => {
        if (!!id) {
            setDataFromList();
        } else {
            formik.handleReset({});
            formik.setFieldValue("schedule", {
                label: "Now",
                value: "now",
            });
        }
    };

    const handleOnChangeSchedule = (param: any) => {
        formik.setFieldValue("schedule", param);

        if (param.value === "weekly") {
            formik.setFieldValue("date", {
                label: "Monday",
                value: "2",
                type: "weekly",
            });
        }

        if (param.value === "monthly") {
            formik.setFieldValue("date", {
                label: "01th",
                value: "01",
                type: "monthly",
            });
        }
    };

    const onEmojiTitleClick = (event: any, emojiObject: any) => {
        setEmojiTitle(event.emoji);
    };

    const onEmojiContentClick = (event: any, emojiObject: any) => {
        setEmojiContent(event.emoji);
    };

    const handleCreateCampaign = () => {
        handleSubmit(formik.values);
    };

    const onCloseConfirmCreateClick = () => {
        setIsConfirmCreate((_prev) => false);
    };

    const showListMedia = (domain: any) => {
        const show: any[] = [];

        if (Array.isArray(domain) && domain.length > 0 && domain.length === 1) {
            // eslint-disable-next-line
            domain.map((value: any) => {
                const { label } = value;
                show.push(<span className="text-primary">{label ?? ""}</span>);
            });
        }

        if (Array.isArray(domain) && domain.length > 0 && domain.length > 1) {
            // eslint-disable-next-line
            domain.map((value: any, key) => {
                const { label } = value;
                show.push(
                    <>
                        <span className="text-primary">{`${label ?? ""}`}</span>
                        <span>{key <= domain.length - 2 ? ` ,` : ""}</span>
                    </>
                );
            });
        }

        return show;
    };

    const showTitleModalCreateCampaign = () => {
        const domainSelect = formik?.values?.domain || [OPTIONS_MEDIA[0]];

        if (!!id && !isCopy) {
            return (
                <div style={{ lineHeight: "28px" }}>
                    <span>{t("Update a campaign with media")} </span>
                    {showListMedia(formik?.values?.domain)}
                    <span> ?</span>
                </div>
            );
        } else {
            return (
                <div style={{ lineHeight: "28px" }}>
                    <span>{t("Create a campaign with media")} </span>
                    {showListMedia(domainSelect)}
                    <span className="text-primary">
                        {(domainSelect as any)?.label ? (domainSelect as any)?.label : ""}
                    </span>
                    <span> ?</span>
                </div>
            );
        }
    };

    const listDomainALL = [
        { id: "all", name: t("All Media") },
        ...listDomain.filter((item) => item.id !== ""),
    ];

    const handleOnChangeTag = (value: any) => {
        if (formik?.values?.tags?.find((item: any) => item === value?.text)) {
            return;
        } else {
            formik?.setFieldValue("tags", value);
        }
    };

    return (
        <React.Fragment>
            {isLoadingDetail && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 3,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: "rgb(164 164 164 / 36%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner size="sm" color="primary"></Spinner>
                </div>
            )}
            <form onSubmit={formik.handleSubmit} onReset={handleReset}>
                <Row>
                    <Col lg={12}>
                        <Card id="leadsList">
                            <CardBody className="pt-3">
                                <Row className={`mt-1 g-5 ${isModal ? "mb-0" : "mb-5"}`}>
                                    {!isModal && (
                                        <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>
                                    )}
                                    <Col
                                        sm={12}
                                        md={12}
                                        lg={isModal ? 7 : 6}
                                        xl={isModal ? 7 : 6}
                                        className={isModal ? "mt-2" : ""}
                                    >
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Campaign Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        onChange={(e: any) =>
                                                            formik.setFieldValue(
                                                                "campaignName",
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder={`${t("Enter Campaign Name")}...`}
                                                        value={formik.values?.campaignName}
                                                    />
                                                    {formik.touched.campaignName &&
                                                        formik.errors.campaignName ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.campaignName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Title")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div className="position-relative">
                                                        <Input
                                                            type="text"
                                                            onChange={(e: any) =>
                                                                formik.setFieldValue("title", e.target.value)
                                                            }
                                                            placeholder={`${t("Enter Title")}...`}
                                                            value={formik.values?.title}
                                                        />
                                                        <div className="col-auto position-absolute top-0 end-0">
                                                            <div className="chat-input-links me-2">
                                                                <div className="links-list-item">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-decoration-none emoji-btn"
                                                                        id="emoji-btn"
                                                                        onClick={() => {
                                                                            setEmojiPickerContent(false);
                                                                            setEmojiPickerTitle(!emojiPickerTitle);
                                                                        }}
                                                                    >
                                                                        <i className="bx bx-smile align-middle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {emojiPickerTitle && (
                                                            <div ref={emojiPickerContainerTitleRef}>
                                                                {" "}
                                                                <EmojiPicker
                                                                    onEmojiClick={onEmojiTitleClick}
                                                                    width={350}
                                                                    height={382}
                                                                />{" "}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {formik.touched.title && formik.errors.title ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Content")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div className="position-relative">
                                                        <textarea
                                                            className="form-control"
                                                            rows={6}
                                                            onChange={(e: any) =>
                                                                formik.setFieldValue("content", e.target.value)
                                                            }
                                                            placeholder={`${t("Enter Content")}...`}
                                                            value={formik.values?.content}
                                                        />
                                                        <div className="col-auto position-absolute top-0 end-0">
                                                            <div className="chat-input-links me-2">
                                                                <div className="links-list-item">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-decoration-none emoji-btn"
                                                                        id="emoji-btn"
                                                                        onClick={() => {
                                                                            setEmojiPickerTitle(false);
                                                                            setEmojiPickerContent(
                                                                                !emojiPickerContent
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="bx bx-smile align-middle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {emojiPickerContent && (
                                                            <div ref={emojiPickerContainerContentRef}>
                                                                {" "}
                                                                <EmojiPicker
                                                                    onEmojiClick={onEmojiContentClick}
                                                                    width={350}
                                                                    height={382}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {formik.touched.content && formik.errors.content ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.content}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12} md={12}>
                                                <div className="position-relative">
                                                    <label className="form-label">{t("URL")}</label>
                                                    <div className="position-relative">
                                                        <Input
                                                            type="text"
                                                            onChange={(e: any) => {
                                                                formik.setFieldValue("url", e.target.value);
                                                            }}
                                                            placeholder={`${t("Enter URL")}...`}
                                                            value={formik.values?.url}
                                                            className="pe-4"
                                                        />
                                                        {formik.touched.url && formik.errors.url ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.url}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Click Tag")}
                                                        <span
                                                            className="ms-1"
                                                            style={{
                                                                listStyleType: "none",
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <TooltipCustom
                                                                title={t("This tag will be stored when click push notification message")}
                                                                id={`note-image-url`}
                                                                className="text-secondary"
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary"></i>
                                                            </TooltipCustom>
                                                        </span>
                                                    </label>
                                                    <Hashtag
                                                        placeholder={`${t("Enter Click Tag")}...`}
                                                        initialValue={initialValueTags || []}
                                                        onChangeTag={(event) => {
                                                            handleOnChangeTag(event);
                                                        }}
                                                        idSelected="hashtags-CampaignFormMulti"
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Image URL")}
                                                        <span
                                                            className="ms-1"
                                                            style={{
                                                                listStyleType: "none",
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <TooltipCustom
                                                                title={t(
                                                                    "The recommended size is 360 * 180px, and the maximum size is 1M. Supported formats: JPG, PNG, GIF. Chrome, Opera, and Edge browsers on the Win platform are supported. Pictures can be network resources starting with http or https."
                                                                )}
                                                                id={`note-image-url`}
                                                                className="text-secondary"
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary"></i>
                                                            </TooltipCustom>
                                                        </span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        onChange={(e: any) =>
                                                            formik.setFieldValue("imageURL", e.target.value)
                                                        }
                                                        placeholder={`${t("Enter Image URL")}...`}
                                                        value={formik.values?.imageURL}
                                                    />
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>

                                    <Col
                                        sm={12}
                                        md={12}
                                        lg={isModal ? 5 : 4}
                                        xl={isModal ? 5 : 4}
                                        className={isModal ? "mt-2" : ""}
                                    >
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div>
                                                        <SearchFilterDomain
                                                            name="domain"
                                                            id="domain"
                                                            isMulti={true}
                                                            dataList={
                                                                formik?.values?.domain &&
                                                                    (formik.values?.domain as any).length !==
                                                                    listDomainALL.length - 1
                                                                    ? listDomainALL
                                                                    : listDomain.filter((item) => item.id !== "")
                                                            }
                                                            initialValue={formik.values.domain}
                                                            onChangeSelect={(option: any) => {
                                                                if (
                                                                    option.find(
                                                                        (item: any) => item.value === "all"
                                                                    ) !== undefined
                                                                ) {
                                                                    const allOptionsExceptAll =
                                                                        listDomainALL.filter(
                                                                            (option) => option.id !== "all"
                                                                        );
                                                                    const allOptionsExceptAllConvert =
                                                                        allOptionsExceptAll.map((value) => {
                                                                            const { id, name, website } = value;
                                                                            return {
                                                                                label: name,
                                                                                value: id,
                                                                                website,
                                                                            };
                                                                        });

                                                                    formik.setFieldValue(
                                                                        "domain",
                                                                        allOptionsExceptAllConvert
                                                                    );
                                                                } else {
                                                                    formik.setFieldValue("domain", option);
                                                                }
                                                            }}
                                                            isDisabled={!!id && !isCopy ? true : false}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                    {formik.touched.domain && formik.errors.domain ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.domain}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Target Tag")}
                                                        <span
                                                            className="ms-1"
                                                            style={{
                                                                listStyleType: "none",
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <TooltipCustom
                                                                title={t("The message will be sent to users who clicked message with this tag")}
                                                                id={`note-image-url`}
                                                                className="text-secondary"
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary"></i>
                                                            </TooltipCustom>
                                                        </span>
                                                    </label>
                                                    <SearchFilterTag
                                                        isMulti={true}
                                                        limitShow={10}
                                                        initialValue={initialValueTargetTags}
                                                        isClearable={true}
                                                        onChangeSelect={(event) => {
                                                            formik?.setFieldValue("target_tag", event);
                                                        }}
                                                        placeholder={`${t("Enter Target Tag")}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12} className="mt-4 pt-1 mb-0">
                                                <div className="m-auto" style={{ width: '40%', opacity: '0.4', borderTop: '1px dashed var(--vz-secondary)' }}></div>
                                            </Col>
                                            <Col sm={12} md={12} className="mt-1">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Schedule")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <ScheduleType
                                                        name="schedule"
                                                        id="schedule"
                                                        dataList={SCHEDULE_OPTIONS_LANG}
                                                        placeholder={`${t("Select Schedule")}...`}
                                                        initialValue={
                                                            formik.values.schedule || SCHEDULE_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(value: any) => {
                                                            handleOnChangeSchedule(value);
                                                        }}
                                                    />
                                                    {formik.touched.schedule && formik.errors.schedule ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.schedule}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                {(formik.values?.schedule as any)?.value !==
                                                    "custom" ? (
                                                    <Row>
                                                        <Col sm={7} md={7}>
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Date")}{" "}
                                                                    {OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        (formik.values?.schedule as any)?.value
                                                                    ) && <span className="text-danger"> *</span>}
                                                                </label>
                                                                <Select
                                                                    options={
                                                                        DATE_OPTIONS_LANG?.filter(
                                                                            (op) =>
                                                                                op?.type ===
                                                                                (formik.values?.schedule as any)?.value
                                                                        ) || []
                                                                    }
                                                                    value={
                                                                        !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                            (formik.values?.schedule as any)?.value
                                                                        )
                                                                            ? null
                                                                            : (formik?.values?.date as any)
                                                                    }
                                                                    name={`date`}
                                                                    id={`date`}
                                                                    autocomplete={false}
                                                                    isDisabled={
                                                                        !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                            (formik.values?.schedule as any)?.value
                                                                        )
                                                                    }
                                                                    placeholder={`${t("Select Date")}...`}
                                                                    className="select-schedule"
                                                                    classNamePrefix="name-prefix"
                                                                    onChange={(event: any) => {
                                                                        formik.setFieldValue(`date`, event);
                                                                    }}
                                                                ></Select>
                                                                {formik.touched.date && formik.errors.date ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.date}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={5} md={5} className="mt-3 mt-sm-0 ps-3 ps-sm-0">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Time")}{" "}
                                                                    {(formik.values?.schedule as any)?.value !==
                                                                        "now" && (
                                                                            <span className="text-danger"> *</span>
                                                                        )}
                                                                </label>
                                                                <div className="date-picker-wrapper-custom">
                                                                    <DatePicker
                                                                        className="form-control search"
                                                                        placeholderText={`${t("Select Time")}...`}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        disabled={
                                                                            (formik.values?.schedule as any)
                                                                                ?.value === "now"
                                                                        }
                                                                        // timeFormat="p"
                                                                        timeIntervals={5}
                                                                        autoComplete="off"
                                                                        // dateFormat="Pp"
                                                                        isClearable={true}
                                                                        timeCaption={t("Time")}
                                                                        timeFormat="HH:mm"
                                                                        // disabled
                                                                        value={
                                                                            (formik.values?.schedule as any)
                                                                                ?.value === "now"
                                                                                ? ""
                                                                                : (formik?.values?.time as any)
                                                                        }
                                                                        locale={
                                                                            i18n?.language === "ko" ? "ko" : "en"
                                                                        }
                                                                        name={`time`}
                                                                        // Y-MM-DD
                                                                        onChange={(value: Date | null) =>
                                                                            formik.setFieldValue(
                                                                                `time`,
                                                                                value
                                                                                    ? moment(new Date(value)).format(
                                                                                        "HH:mm"
                                                                                    )
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                {formik.touched.time && formik.errors.time ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.time}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col sm={7} md={7}>
                                                            <label className="form-label">
                                                                {t("Date")}{" "}
                                                                {(formik.values?.schedule as any)?.value ===
                                                                    "custom" && (
                                                                        <span className="text-danger"> *</span>
                                                                    )}
                                                            </label>
                                                            <div id="date-picker_schedule" className="w-100">
                                                                <DatePicker
                                                                    className="form-control search"
                                                                    placeholderText={`${t("Date")}...`}
                                                                    isClearable={true}
                                                                    dateFormat="yyyy-MM-DD"
                                                                    minDate={new Date()}
                                                                    locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                    value={
                                                                        (formik.values?.schedule as any)?.value !==
                                                                            "custom"
                                                                            ? ""
                                                                            : (formik?.values?.custom_date as any)
                                                                    }
                                                                    name={`custom_date`}
                                                                    onChange={(value: Date | null) => {
                                                                        formik.setFieldValue(
                                                                            `custom_date`,
                                                                            value
                                                                                ? moment(new Date(value)).format(
                                                                                    "yyyy-MM-DD"
                                                                                )
                                                                                : ""
                                                                        );
                                                                    }}
                                                                />
                                                                {formik.touched.custom_date &&
                                                                    formik.errors.custom_date ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.custom_date}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={5} md={5} className="mt-3 mt-sm-0 ps-3 ps-sm-0">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Time")}{" "}
                                                                    {(formik.values?.schedule as any)?.value !==
                                                                        "now" && (
                                                                            <span className="text-danger"> *</span>
                                                                        )}
                                                                </label>
                                                                <div className="date-picker-wrapper-custom">
                                                                    <DatePicker
                                                                        className="form-control search"
                                                                        placeholderText={`${t("Select Time")}...`}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        disabled={
                                                                            (formik.values?.schedule as any)
                                                                                ?.value === "now"
                                                                        }
                                                                        // timeFormat="p"
                                                                        timeIntervals={5}
                                                                        autoComplete="off"
                                                                        // dateFormat="Pp"
                                                                        isClearable={true}
                                                                        timeCaption={t("Time")}
                                                                        timeFormat="HH:mm"
                                                                        // disabled
                                                                        value={
                                                                            (formik.values?.schedule as any)
                                                                                ?.value === "now"
                                                                                ? ""
                                                                                : (formik?.values?.time as any)
                                                                        }
                                                                        locale={
                                                                            i18n?.language === "ko" ? "ko" : "en"
                                                                        }
                                                                        name={`time`}
                                                                        // Y-MM-DD
                                                                        onChange={(value: Date | null) =>
                                                                            formik.setFieldValue(
                                                                                `time`,
                                                                                value
                                                                                    ? moment(new Date(value)).format(
                                                                                        "HH:mm"
                                                                                    )
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                {formik.touched.time && formik.errors.time ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.time}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Col>
                                            <div className="col-lg-12 mt-4">
                                                <div
                                                    className={`hstack gap-2 justify-content-md-start justify-content-lg-end mt-3`}
                                                >
                                                    <button
                                                        className="btn btn-success fs-14"
                                                        color="light"
                                                        type="button"
                                                        onClick={onSaveClick}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : !!id && !isCopy ? (
                                                            <i className="ri-login-circle-line align-bottom me-1"></i>
                                                        ) : (
                                                            <i className="ri-add-fill align-bottom me-1"></i>
                                                        )}
                                                        {!!id && !isCopy
                                                            ? t("Button Update")
                                                            : t("Button Create")}
                                                    </button>

                                                    <button
                                                        className="btn btn-secondary fs-14"
                                                        color="success"
                                                        type="reset"
                                                        disabled={isLoading}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>
                                                        {t("Button Reset")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </form>
            <ToastContainer closeButton={false} limit={1} />
            <ModalConfirm
                textButtonConfirm={
                    !!id && !isCopy ? t("Button Update") : t("Button Create")
                }
                classButtonConfirm="btn-success"
                classIconButtonConfirm={
                    !!id && !isCopy ? "ri-login-circle-line" : "ri-add-fill"
                }
                header={!!id && !isCopy ? t("Campaign Update") : t("Campaign Create")}
                title={showTitleModalCreateCampaign()}
                content={
                    !!id && !isCopy
                        ? t(
                            "After confirming update action, the data will be update. Do you want to proceed with the confirmation action ?"
                        )
                        : t(
                            "After confirming creation action, the data will be generated. Do you want to proceed with the confirmation action ?"
                        )
                }
                isShowIcon={false}
                isOpen={isOpenConfirmCreate}
                onClose={onCloseConfirmCreateClick}
                onConfirm={handleCreateCampaign}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default CampaignForm;
