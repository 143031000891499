import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";

import { INotificationLog } from "api/types/_notification";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { useRole } from "components/Hooks/UserHooks";
import { SCHEDULE_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import CountUp from "react-countup";
import { getTagStatistics } from "store/thunks";
import LabelWrapper from "components/Common/LabelWrapper";
import InputsRange from "components/Common/InputsRange";
import CopyWrapper from "components/Common/CopyWrapper";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "click";

const formatDate = (strDate: string = '') => {
  return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
}

const TagStatistics = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const { setDurationResponses } = useDurationResponses();

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),

    start_date: withDefault(StringParam, ""),
    end_date: withDefault(StringParam, ""),
    order_by: withDefault(StringParam, `DESC`),
    start_sent: withDefault(StringParam, ""),
    end_sent: withDefault(StringParam, ""),
    start_click: withDefault(StringParam, ""),
    end_click: withDefault(StringParam, ""),
    start_devices: withDefault(StringParam, ""),
    end_devices: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );

  // Total Send to range
  const [startTotalSend, setTotalNotificationFromRangeSearch] = useState<string>(query?.start_sent || "");
  const [endTotalSend, setTotalNotificationToRangeSearch] = useState<string>(query?.end_sent || "");

  // Total Clicked to range
  const [totalClickedFromRangeSearch, setTotalClickedFromRangeSearch] = useState<string>(query?.start_click || "");
  const [totalClickedToRangeSearch, setTotalClickedToRangeSearch] = useState<string>(query?.end_click || "");

  // Total Device to range
  const [totalDeviceFromRangeSearch, setTotalDeviceFromRangeSearch] = useState<string>(query?.start_devices || "");
  const [totalDeviceToRangeSearch, setTotalDeviceToRangeSearch] = useState<string>(query?.end_devices || "");

  //eslint-disable-next-line
  const [previewDetail, setPreviewDetail] = useState<any | null>(null);
  // Inside your component

  const [tagStatistics, setTagStatistics] = useState<{
    list: INotificationLog[];
    total: number;
  }>({ list: [], total: 0 });
  const [isDailyStatisticsLoading, setIsDailyStatisticsLoading] =
    useState<boolean>(false);

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
    });
  };

  useEffect(() => {
    const handleQueryData = async () => {
      setIsDailyStatisticsLoading((prev) => true);
      const res: any = await getTagStatistics(query);
      if (res?.code === 200) {
        setTagStatistics((prev: any) => ({
          list: (prev?.list || []).concat(res?.data?.list || []),
          total: res?.data?.pagination?.total || 0,
        }));
      }
      setIsDailyStatisticsLoading((prev) => false);
      setDurationResponses([{
        name: 'Tag Statistics',
        time: res?.data?.pagination?.duration || 0
      }]);
    };

    handleQueryData();
    //eslint-disable-next-line
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      keyword: keywordSearch || "",
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      start_sent: startTotalSend || "",
      end_sent: endTotalSend || "",
      start_click: totalClickedFromRangeSearch || "",
      end_click: totalClickedToRangeSearch || "",
      start_devices: totalDeviceFromRangeSearch || "",
      end_devices: totalDeviceToRangeSearch || "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTagStatistics((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: "",
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: "",
      end_date: "",
      start_sent: undefined,
      end_sent: undefined,
      start_click: undefined,
      end_click: undefined,
      start_devices: undefined,
      end_devices: undefined,
      page: 1,
      time_request: +new Date(),
      order_by: "DESC",
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTagStatistics((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setDateSearch(["", ""]);
    setTotalNotificationFromRangeSearch("");
    setTotalNotificationToRangeSearch("");
    setTotalClickedFromRangeSearch("");
    setTotalClickedToRangeSearch("");
    setTotalDeviceFromRangeSearch("");
    setTotalDeviceToRangeSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Tag"),
        accessor: "tag",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("Campaigns"),
        accessor: "campaigns",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("Sent"),
        accessor: "sent",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Click"),
        accessor: "click",
        filterable: true,
        sortable: true,
        thClass: "justify-content-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("CTR"),
        accessor: "ctr",
        filterable: true,
        sortable: true,
        thClass: "justify-content-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">
              {formatNumberWithCommas(cell?.value || 0)}%
            </div>
          </>
        ),
      },
      {
        Header: t("Devices"),
        accessor: "devices",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t('First Clicked at'),
        accessor: "first_clicked_at",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        thWidth: 170,
        Cell: (cell: any) => {
          const arrDate = formatDate(cell?.value).split(' ');
          return (
            <div className="text-end">
              {!!cell?.value ? <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span> : ''}
            </div>
          )
        },
      },
      {
        Header: t('Last Clicked at'),
        accessor: "last_clicked_at",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        thWidth: 170,
        Cell: (cell: any) => {
          const arrDate = formatDate(cell?.value).split(' ');
          return (
            <div className="text-end">
              {!!cell?.value ? <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span> : ''}
            </div>
          )
        },
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, userPermissions, SCHEDULE_OPTIONS_LANG]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = (sortBy: any) => {
    if (isDailyStatisticsLoading) {
      return;
    } else {
      setTagStatistics((prev: any) => ({
        list: [],
        total: 0,
      }));
      setQuery((_prev: any) => {
        return { ..._prev, ...sortBy };
      });
    }
  };

  useEffect(() => {
    document.title = `${t("Tag Statistics")} - ${t("Statistics")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Tag Statistics")} pageTitle={t("Statistics")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList" className="ribbon-box right">
                <div
                  className="ribbon round-shape"
                  style={{
                    background: "#1548a0",
                    fontWeight: 300,
                    fontSize: "0.8rem",
                  }}
                >
                  {t("This data is analyzed per 10 mins")}
                </div>
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {isDailyStatisticsLoading ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={tagStatistics?.total || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={12}
                          md={12}
                          xl={4}
                          xxl={3}
                          className="date-picker-wrapper-custom mt-3"
                        >
                          <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                            <DatePickerCustom
                              placeholder={`${t("Start Date")} - ${t(
                                "End Date"
                              )}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          xl={4}
                          xxl={3}
                          className="mt-0 mt-3"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={totalDeviceFromRangeSearch}
                            valueTo={totalDeviceToRangeSearch}
                            placeholderFrom={`${t('Devices (Start)')}...`}
                            placeholderTo={`${t('Devices (End)')}...`}
                            labelFrom={`${t('Devices (Start)')}`}
                            labelTo={`${t('Devices (End)')}`}
                            onChangeFrom={(val) =>
                              setTotalDeviceFromRangeSearch(val)
                            }
                            onChangeTo={(val) =>
                              setTotalDeviceToRangeSearch(val)
                            }
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                      </Row>
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={12}
                          md={12}
                          xl={4}
                          xxl={3}
                          className="mt-0 mt-3"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={totalClickedFromRangeSearch}
                            valueTo={totalClickedToRangeSearch}
                            placeholderFrom={`${t("From Range Total Clicked")}...`}
                            placeholderTo={`${t("To Range Total End")}...`}
                            labelFrom={`${t('From Range Total Clicked')}`}
                            labelTo={`${t('To Range Total End')}`}
                            onChangeFrom={(val) =>
                              setTotalClickedFromRangeSearch(val)
                            }
                            onChangeTo={(val) =>
                              setTotalClickedToRangeSearch(val)
                            }
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          xl={4}
                          xxl={3}
                          className="mt-0 mt-3"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startTotalSend}
                            valueTo={endTotalSend}
                            placeholderFrom={`${t("From Range Total Send")}...`}
                            placeholderTo={`${t("To Range Total Send")}...`}
                            labelFrom={`${t('From Range Total Send')}`}
                            labelTo={`${t('To Range Total Send')}`}
                            onChangeFrom={(val) =>
                              setTotalNotificationFromRangeSearch(val)
                            }
                            onChangeTo={(val) =>
                              setTotalNotificationToRangeSearch(val)
                            }
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-3"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isDailyStatisticsLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="pt-0 px-0">
                  <div>
                    <InfiniteScroll
                      dataLength={tagStatistics?.list?.length || 0}
                      next={handleLoadMore}
                      scrollableTarget="scrollableDiv"
                      hasMore={
                        tagStatistics &&
                          tagStatistics?.list?.length < tagStatistics?.total
                          ? true
                          : false
                      }
                      loader={""} // loader={<LoadingListNotify />}
                      scrollThreshold={"50%"}
                    >
                      <div className="mx-3 my-4">
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={
                            tagStatistics?.list?.length
                              ? tagStatistics?.list
                              : []
                          }
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={tagStatistics?.total}
                          customPageCount={1}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isDailyStatisticsLoading}
                          isShowPagination={false}
                          isShowLoadingBottom={query.page > 1}
                        />
                      </div>
                    </InfiniteScroll>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TagStatistics;
