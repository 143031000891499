import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";

import { IDomain } from "api/types/_domain";
import { INotificationLog } from "api/types/_notification";
import DatePickerCustom from "components/Common/DatePickerCustom";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CountUp from "react-countup";
import { getAllDomains } from "store/domain/thunk";
import {
  getDailyStatistics,
  getNotificationsListAllDevice,
} from "store/notification/thunk";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const DailyStatistics = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const { setDurationResponses } = useDurationResponses();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // sort_by: withDefault(StringParam, 'date'),
    // order_by: withDefault(StringParam, `DESC`),
    start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    start_new_subscriber: withDefault(StringParam, ""),
    end_new_subscriber: withDefault(StringParam, ""),
    start_unsubscription: withDefault(StringParam, ""),
    end_unsubscription: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;



  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  const [domainSearch, setDomainSearch] = useState<Option | null>({
    label:
      listDomain?.filter(
        (item) => String(item?.website || "") === String(query?.domain || "")
      )[0]?.name || t("All Media"),
    value: `${listDomain?.filter(
      (item) => String(item?.website || "") === String(query?.domain || "")
    )[0]?.id || ""
      }`,
  });

  const [previewDetail, setPreviewDetail] = useState<any | null>(null);
  // Inside your component

  const [notificationLogs, setNotificationLogs] = useState<{
    list: INotificationLog[];
    total: number;
  }>({ list: [], total: 0 });

  const [isDailyStatisticsLoading, setIsDailyStatisticsLoading] = useState<boolean>(false);

  // Total Send to range
  const [startNewSubscriberSearch, setNewSubscriberFromRangeSearch] = useState<string>(query?.start_sent || "");
  const [endNewSubscriberSearch, setNewSubscriberToRangeSearch] = useState<string>(query?.end_sent || "");

  // Total Clicked to range
  const [startUnsubscriptionSearch, setUnsubscriptionFromRangeSearch] = useState<string>(query?.start_click || "");
  const [endUnsubscriptionSearch, setUnsubscriptionToRangeSearch] = useState<string>(query?.end_click || "");

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
    });
  };

  useEffect(() => {
    const handleQueryData = async () => {
      setIsDailyStatisticsLoading((prev) => true);
      const res: any = await getDailyStatistics(query);
      if (res?.code === 200) {
        setNotificationLogs((prev: any) => ({
          list: (prev?.list || []).concat(res?.data?.list || []),
          total: res?.data?.pagination?.total || 0,
        }));
      }
      setIsDailyStatisticsLoading((prev) => false);
      setDurationResponses([{
        name: 'Daily Statistics',
        time: res?.data?.pagination?.duration || 0
      }]);
    };

    handleQueryData();
    //eslint-disable-next-line
  }, [JSON.stringify(query)]);


  const searchData = () => {
    const queryNew = {
      ...query,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      page: 1,
      time_request: +new Date(),
      start_new_subscriber: startNewSubscriberSearch,
      end_new_subscriber: endNewSubscriberSearch,
      start_unsubscription: startUnsubscriptionSearch,
      end_unsubscription: endUnsubscriptionSearch,
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      time_request: +new Date(),
      start_new_subscriber: undefined,
      end_new_subscriber: undefined,
      start_unsubscription: undefined,
      end_unsubscription: undefined,
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew, "push");
    setDomainSearch({ label: t("All Media"), value: "" });
    setDateSearch([
      moment(queryNew?.start_date || "", "Y-MM-DD").toDate(),
      moment(queryNew?.end_date || "", "Y-MM-DD").toDate(),
    ]);
    setNewSubscriberFromRangeSearch("");
    setNewSubscriberToRangeSearch("");
    setUnsubscriptionFromRangeSearch("");
    setUnsubscriptionToRangeSearch("");
  };

  function onClosePreviewDetailClick() {
    setPreviewDetail((_prev: any) => null);
  }

  const formatDate = (strDate: string = "") => {
    if (!strDate) {
      return "";
    }
    return `${String(strDate).split("T")[0]} ${String(String(strDate).split("T")[1]).split(".")[0]
      }`;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 130,
        minWidth: 130,
        Cell: (cell: any) => {
          const arrDate = formatDate(cell?.value).split(" ");
          return (
            <>
              <span>
                <span>{arrDate?.[0]}</span>{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: t("Accumulate Subscriber"),
        accessor: "accumulate_subscriber",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("Activated Subscriber"),
        accessor: "activated_subscriber",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("New Subscriber"),
        accessor: "new_subscriber",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("UnSubscription"),
        accessor: "unsubscription",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("Campaigns"),
        accessor: "campaigns",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("Sent"),
        accessor: "sent",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end">
                {formatNumberWithCommas(cell?.value)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Click"),
        accessor: "click",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("CTR"),
        accessor: "ctr",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value || 0)}%
            </div>
          </>
        ),
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, userPermissions]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    //eslint-disable-next-line
  }, []);

  const handleSetListOS = (resListAllOS: any) => {
    if (resListAllOS && resListAllOS?.data && resListAllOS?.data?.list) {
      const listALLOS = resListAllOS?.data?.list.map((item: string) => ({
        label: item,
        value: item,
      }));
      return listALLOS;
    } else {
      return [];
    }
  };

  const handleSetListDevice = (resListDevice: any) => {
    if (resListDevice && resListDevice?.data && resListDevice?.data?.list) {
      const listAllDevice = resListDevice?.data?.list.map((item: string) => ({
        label: t(item),
        value: item,
      }));
      return listAllDevice;
    } else {
      return [];
    }
  };

  const handleCallAllOption = async () => {
    try {
      // const [resDomain, resListAllOS, resListAllDevice]: any = await Promise.all([getAllDomains(), getNotificationsListAllOS(), getNotificationsListAllDevice()]);
      const [resDomain]: any = await Promise.all([
        getAllDomains(),
        getNotificationsListAllDevice(),
      ]);
      if (resDomain) {
        const res = resDomain?.data?.list?.map((item: any, index: number) => ({
          id: item?.id,
          name: item?.name,
          website: item?.domain,
        }));
        const list = [
          { name: t("All Media"), id: "", website: "" } as any,
        ].concat(res || []);
        setListDomain((_prev) => list);
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (domainSearch) {
      setListDomain((_prev) =>
        (_prev || [])?.map((item) =>
          item?.id ? item : { ...item, name: t("All Media") }
        )
      );
      !domainSearch?.value &&
        setDomainSearch((prev) => ({ label: t("All Media"), value: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Daily Statistics")} - ${t("Statistics")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Daily Statistics")}
            pageTitle={t("Statistics")}
          />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.NEWS_LOG_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList" className="ribbon-box right">
                    <div
                      className="ribbon round-shape"
                      style={{
                        background: "#1548a0",
                        fontWeight: 300,
                        fontSize: "0.8rem",
                      }}
                    >
                      {t("This data is analyzed per 10 mins")}
                    </div>
                    <CardHeader className="border-0">
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isDailyStatisticsLoading ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={notificationLogs?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center mt-0">
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="date-picker-wrapper-custom mt-3"
                            >
                              <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                <DatePickerCustom
                                  placeholder={`${t("Start Date")} - ${t(
                                    "End Date"
                                  )}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  onChangePicker={handleChangePicker}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startNewSubscriberSearch}
                                valueTo={endNewSubscriberSearch}
                                placeholderFrom={`${t("New Subscriber (Start)")}...`}
                                placeholderTo={`${t("New Subscriber (End)")}...`}
                                labelFrom={`${t('New Subscriber (Start)')}`}
                                labelTo={`${t('New Subscriber (End)')}`}
                                onChangeFrom={(val) =>
                                  setNewSubscriberFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setNewSubscriberToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startUnsubscriptionSearch}
                                valueTo={endUnsubscriptionSearch}
                                placeholderFrom={`${t("Unsubscription (Start)")}...`}
                                placeholderTo={`${t("Unsubscription (End)")}...`}
                                labelFrom={`${t('Unsubscription (Start)')}`}
                                labelTo={`${t('Unsubscription (End)')}`}
                                onChangeFrom={(val) =>
                                  setUnsubscriptionFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setUnsubscriptionToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-3"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary me-1"
                                  onClick={searchData}
                                  disabled={isDailyStatisticsLoading}
                                >
                                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                                  {t("Button Search")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-14"
                                  onClick={resetData}
                                >
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t("Button Reset")}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>

                    <CardBody className="pt-0 px-0">
                      <div>
                        <InfiniteScroll
                          dataLength={notificationLogs?.list?.length || 0}
                          next={handleLoadMore}
                          scrollableTarget="scrollableDiv"
                          hasMore={
                            notificationLogs &&
                              notificationLogs?.list?.length <
                              notificationLogs?.total
                              ? true
                              : false
                          }
                          loader={""} // loader={<LoadingListNotify />}
                          scrollThreshold={"50%"}
                        >
                          <div className="mx-3 my-4">
                            <TableContainer
                              className="custom-header-css"
                              divClass="table-card"
                              tableClass="align-middle"
                              theadClass="table-light"
                              columns={columns}
                              data={
                                notificationLogs?.list?.length
                                  ? notificationLogs?.list
                                  : []
                              }
                              customPageSize={query.limit}
                              customPageIndex={query.page - 1}
                              totalRecords={notificationLogs?.total}
                              customPageCount={1}
                              handleChangePage={handleChangePage}
                              manualSorting={true}
                              sorting={{
                                sort_by: query.sort_by,
                                order_by: query.order_by,
                              }}
                              handleChangeSorting={handleChangeSorting}
                              isLoading={isDailyStatisticsLoading}
                              isShowPagination={false}
                              isShowLoadingBottom={query.page > 1}
                            />
                          </div>
                        </InfiniteScroll>
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
        <Modal
          isOpen={!!previewDetail}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={onClosePreviewDetailClick}
          keyboard={true}
        >
          <ModalHeader toggle={onClosePreviewDetailClick}>
            {t("Detail")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_id")}
                                </td>
                                <td>{previewDetail?.id}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("Date")}</td>
                                <td>{previewDetail?.date}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Accumulate Subscriber")}
                                </td>
                                <td>{previewDetail?.accumulate_subscriber}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("New Subscriber")}
                                </td>
                                <td>{previewDetail?.new_subscriber}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("Sent")}</td>
                                <td>{previewDetail?.total_send}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("Click")}</td>
                                <td>{previewDetail?.total_clicked}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("CTR")}</td>
                                <td>{previewDetail?.ctr}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Created at")}
                                </td>
                                <td>{previewDetail?.created_at}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12 mb-4">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-soft-secondary fs-14"
                          type="button"
                          color="light"
                          onClick={onClosePreviewDetailClick}
                        >
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t("Button Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DailyStatistics;
